const template = require('./OverviewList.jade');
const OverviewMixin = require('./OverviewMixin');
const Account = require('../../../authentication/Account');
const ApplicationConfig = require('../../../app/ApplicationConfig');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const TextFormatter = require('../../../../common/TextFormatter');

// @vue/component
module.exports = {
    mixins: [
        OverviewMixin,
        i18nMixin({
            prefix: 'OverviewList.',
        }),
    ],
    props: {
        articleEditionEnabled: Boolean,
        dataModeListEnabled: Boolean,
    },
    computed: {
        displayAdPublicationCertificateLink() {
            return Account.isAdmin()
                || ApplicationConfig.hasToDisplayPublicationCertificateBtn
                || Account.isPublicationCertificateViewer();
        },
        realEstateAdPublicationDate() {
            return this.realEstateAd.publicationDate;
        },
        realEstateAdModificationDate() {
            return this.realEstateAd.modificationDate;
        },
        publicationDate() {
            return TextFormatter.formatAndTranslateField(this.realEstateAdPublicationDate, 'publicationDate', this.realEstateAd);
        },
        modificationDate() {
            return TextFormatter.formatAndTranslateField(this.realEstateAdModificationDate, 'modificationDate', this.realEstateAd);
        },
        publicationDateTitle() {
            return TextFormatter.formatDetailedDate(this.realEstateAdPublicationDate);
        },
        modificationDateTitle() {
            return TextFormatter.formatDetailedDate(this.realEstateAdModificationDate);
        },
        realEstateAdReference() {
            return this.realEstateAd.reference;
        },
        referenceText() {
            return this.t('reference', {
                reference: this.realEstateAdReference,
            });
        },
        displayAdModificationLink() {
            return this.realEstateAdOnTheMarket && (this.canModifyAd || this.canModifyAdBlur) && !this.canBoostAds;
        },
    },
    template: template(),
};
