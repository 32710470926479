const _ = require('lodash');

const AddLinkIfNeeded = require('./AddLinkIfNeeded');
const RealEstateAdNotificationBoostButton = require('../../../notificationBoost/components/RealEstateAdNotificationBoostButton');
const RealEstateAdTitleGenerator = require('../../../../common/RealEstateAdTitleGenerator');
const isSoldRealEstateAd = require('../../../utils/isSoldRealEstateAd');
const Account = require('../../../authentication/Account');
const ProgrammeHelper = require('../../../../common/ProgrammeHelper');
const {canMarkAdsAsLeading, canHighlightAds} = require('../../../setFeatured');
const {canBoostAd} = require('../../../notificationBoost');

const SORT_BY_LIST_TO_DISPLAY_RELEVANCE = [
    'relevance',
    'relevanceDev',
];

// @vue/component
module.exports = {
    components: {
        AddLinkIfNeeded,
        RealEstateAdNotificationBoostButton,
    },
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        sortBy: {
            type: String,
            default: undefined,
            SORT_BY_LIST_TO_DISPLAY_RELEVANCE,
        },
        lastViewDateForSearch: {
            type: String,
            default: null,
        },
        ofTheLocationText: {
            type: String,
            default: null,
        },
        disableAlreadySeen: Boolean,
        displayedAsHighlighted: Boolean,
        enableLastContactDateTag: Boolean,
        isRowWidthUnderStandardValue: Boolean,
        displayFavorite: Boolean,
    },
    computed: {
        generatedTitleWithHighlight() {
            return RealEstateAdTitleGenerator.getTitle(
                this.realEstateAd,
                'adOverviewTitle'
            );
        },
        realEstateAdLink() {
            return (!isSoldRealEstateAd(this.realEstateAd) || Account.isSoldAdsViewer())
                ? this.realEstateAd.detailedSheetUrl
                : null;
        },
        realEstateAdDescription() {
            return this.realEstateAd.description;
        },
        displayRelevance() {
            return _.includes(SORT_BY_LIST_TO_DISPLAY_RELEVANCE, this.sortBy) && this.isAdsRelevanceViewer;
        },
        realEstateAdId() {
            return this.realEstateAd.id;
        },
        realEstateAdOnTheMarket() {
            return this.realEstateAd.status.onTheMarket;
        },
        isAdsRelevanceViewer() {
            return Account.isAdsRelevanceViewer();
        },
        isNotLot() {
            const isLot = ProgrammeHelper.isLot(this.realEstateAd);
            return !isLot;
        },
        displayMarkAsLeadingAdButton() {
            return canMarkAdsAsLeading(this.realEstateAd) && this.isNotLot;
        },
        canBuyTemporaryLeadingAds() {
            return Account.canBuyTemporaryLeadingAds();
        },
        displayHighlightAdButton() {
            return canHighlightAds(this.realEstateAd) && this.isNotLot;
        },
        canBoostAds() {
            return canBoostAd(this.realEstateAd) && this.isNotLot;
        },
        canChangeOnTheMarket() {
            return this.realEstateAd.userRelativeData.canChangeOnTheMarket;
        },
        canModifyAd() {
            return this.realEstateAd.userRelativeData.canModifyAd;
        },
        canModifyAdBlur() {
            return this.realEstateAd.userRelativeData.canModifyAdBlur;
        },
    },
    methods: {
        onAdClick(event) {
            this.$emit('click-on-ad', event);
        },
        onBackOnTheMarket(modifiedAd) {
            this.$emit('back-on-the-market', modifiedAd);
        },
    },
};
