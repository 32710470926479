const _ = require('lodash');
const template = require('./ContactApplicationSwitch.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const {
    getDossiers,
    updateDossier,
} = require('../Dossier');
const ApplicantEditor = require('./applicant/ApplicantEditor');
const PresentationModal = require('./PresentationModal');
const VerificationModal = require('./VerificationModal');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const {initDossier} = require('../Dossier');

// @vue/component
module.exports = {
    components: {
        ApplicantEditor,
        PresentationModal,
        VerificationModal,
    },
    mixins: [
        require('../../form/components/mixins/withInternalValue'),
        i18nMixin({
            prefix: 'ContactApplicationSwitch.',
            keys: [
                'title',
                'newBadge',
                'text',
                'errorMessage',
                'retryButton',
                'dossierLoading',
                'modalValidationButtonText',
                'modalIncompleteButtonText',
                'modalLoadingButtonText',
                'wantsToSubmitRentalApplicationCheckboxText',
                'errorToSubmitRentalApplicationText',
                'presentationButton',
                'createDossier',
                'noDossiers',
                'addParticipant',
                'completeDossierActionMessage',
                'incompleteDossierActionMessage',
            ],
        }),
        require('../../form/components/mixins/withInternalValue'),
    ],
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    submitRentalApplication: false,
                    dossiers: [],
                };
            },
        },
        hadAnErrorCreatingTheRentalApplication: Boolean,
        accountType: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            isGettingDossiers: false,
            dossiers: [],
            hasError: false,
            showApplicantEditorModal: false,
            showPresentationModal: false,
            showVerificationModal: false,
            DocumentsErrorsByCat: [],
            dossierToSubmit: [],
        };
    },
    computed: {
        dossierStatusMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierMessage',
                'incompleteDossierMessage',
                'noDossiers'
            );
        },
        dossierStatusActionMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierActionMessage',
                'incompleteDossierActionMessage',
                'createDossier'
            );
        },
        hasCompleteDossier() {
            return _.get(this.dossier, 'complete', false);
        },
        isRegistered() {
            return Account.isRegistered();
        },
        text() {
            return this.t('text', {accountType: this.accountType});
        },
        documentsCategories() {
            return this.$store.getters['rentalApplication/documentsCategories'];
        },
        uploadsFinished() {
            return this.$store.getters['rentalApplication/uploadsFinished'];
        },
        errors() {
            return this.$store.getters['rentalApplication/errors'];
        },
        isProcessing() {
            return this.$store.getters['rentalApplication/isProcessing'];
        },
        documents() {
            return this.$store.getters['rentalApplication/documents'];
        },
        documentsToUpload() {
            return this.$store.getters['rentalApplication/documentsToUpload'];
        },
        submitText() {
            if (this.isProcessing) {
                return this.modalLoadingButtonText;
            } else {
                return this.modalValidationButtonText;
            }
        },
        otherSubTypes() {
            return this.$store.getters['rentalApplication/otherSubTypes'];
        },
        stateIdSubType() {
            return this.$store.getters['rentalApplication/stateIdSubType'];
        },
        documentWithRectoVerso() {
            return this.$store.getters['rentalApplication/documentWithRectoVerso'];
        },
        isFirstTimeRentalApplication() {
            return this.dossiers.length === 0;
        },
        currentDossier() {
            return this.$store.getters['rentalApplication/currentDossier'];
        },
        loadedDossiers() {
            return this.$store.getters['rentalApplication/loadedDossiers'];
        },
    },
    watch: {
        isProcessing(processing) {
            if (!processing) {
                this.addRectoVersoErrors();
                this.DocumentsErrorsByCat = this.getDocumentsErrorsByCat();
                if (this.DocumentsErrorsByCat.length === 0
                    && this.documentsToUpload.length === 0) {
                    // The dossier is complete
                    this.showApplicantEditorModal = false;
                }
            }
        },
        loadedDossiers(dossiers) {
            this.dossiers = dossiers;
        },
    },
    mounted() {
        if (this.isRegistered) {
            // TODO: weird, its called twice
            this.fetchDossiers();
        }
    },
    methods: {
        fetchDossiers() {
            this.$store.commit('rentalApplication/reset');
            this.$store.commit('rentalApplication/resetDocuments');

            this.hasError = false;
            this.isGettingDossiers = true;
            getDossiers((err, dossiers) => {
                if (err) {
                    this.hasError = true;
                } else {
                    this.$store.commit(
                        'rentalApplication/setLoadedDossiers',
                        _.map(dossiers, (dossier) => {
                            return {
                                checked: false,
                                ...dossier,
                            };
                        })
                    );
                }
                this.isGettingDossiers = false;
            });
        },
        getTranslationBasedOnDossierStatus(completeDossierKey, incompleteDossierKey, createDossier) {
            const {dossier} = this;
            let message;
            if (this.isFirstTimeRentalApplication) {
                message = this.t(createDossier);
            } else if (dossier) {
                message = this.t(dossier.complete ? completeDossierKey : incompleteDossierKey);
            }
            return message;
        },
        openPresentationModal() {
            this.showPresentationModal = true;
        },
        openVerificationModal() {
            this.showVerificationModal = true;
        },
        openNextStep(dossier = null) {
            this.$store.commit('rentalApplication/reset');
            if (!dossier) {
                this.isGettingDossiers = true;
                initDossier((err, dossier) => {
                    if (err) {
                        this.hasError = true;
                        console.error(err);
                    } else {
                        this.$store.commit('rentalApplication/setCurrentDossier', dossier);
                        this.showApplicantEditorModal = true;
                    }
                    this.isGettingDossiers = false;
                });
            } else {
                this.$store.commit('rentalApplication/setCurrentDossier', dossier);
                this.showApplicantEditorModal = true;
            }
        },
        openParticipantModal() {
            this.showDossierEditorModal = true;
        },
        submit() {
            updateDossier(this.currentDossier.id, this.currentDossier.type, this.currentDossier.title, (err) => {
                if (err) {
                    this.hasError = true;
                } else {
                    this.$store.commit('rentalApplication/reset');
                    this.$store.commit('rentalApplication/startProcessing');
                }
                this.isGettingDossiers = false;
            });
        },
        getDocumentsErrorsByCat() {
            const documentsCategoriesErrors
                = _(this.errors)
                    .groupBy('category')
                    .map((errors, category) => ({
                        category,
                        errors: _.uniq(_.map(errors, 'error')),
                    }))
                    .value();
            _.forEach(this.documentsCategories, (category) => {
                if (!_.some(this.documents, {documentType: category})) {
                    documentsCategoriesErrors.push({
                        category,
                        errors: ['unAvailable'],
                    });
                }
            });
            this.$store.commit('rentalApplication/addDocumentsCategoriesErrors', documentsCategoriesErrors);
            return documentsCategoriesErrors;
        },
        addRectoVersoErrors() {
            // Ignore verification if user has uploaded a doc containing both sides
            const hasDocWithBothSides = _.some(this.documents, (document) => {
                return _.includes(document.metadata.subType, _.join(_.keys(this.otherSubTypes), '_'));
            });
            if (!hasDocWithBothSides) {
                _.forEach(this.documents, (file) => {
                    if (
                        _.includes(file.metadata.subType, this.stateIdSubType) &&
                        _.includes(this.documentWithRectoVerso, file.documentType)
                    ) {
                        const subType =
                            _.replace(file.metadata.subType, `${this.stateIdSubType}_`, '');
                        const otherSidOfDocSubType =
                            `${this.stateIdSubType}_${this.otherSubTypes[subType]}`;
                        const hasRectoOrVerso = this.documents.some(
                            document => document.metadata.subType === otherSidOfDocSubType
                        );
                        if (!hasRectoOrVerso) {
                            this.$store.commit(
                                'rentalApplication/addError',
                                {
                                    category: this.getDocumentErrorCategory(file.fileName),
                                    error: `messing${_.upperFirst(_.lowerCase(this.otherSubTypes[subType]))}`,
                                    id: file.id,
                                    oldFilename: file.oldFilename,
                                }
                            );
                        } else {
                            this.$store.commit('rentalApplication/removeError', file);
                        }
                    }
                });
            }
        },
        getDocumentErrorCategory(fileName) {
            return _.find(
                this.$store.getters['rentalApplication/documentsCategories'],
                (docCategory) => _.includes(fileName, docCategory)
            );
        },
        getDossierLabel(dossier) {
            const dossierDate = DateFormatter.humanizeDay(dossier.updatedAt) + ' ' + DateFormatter.humanizeTime(dossier.updatedAt);
            const dossierLabel = `${dossier.title ? dossier.title : ''} ${dossier.complete ? dossierDate : ''}`;
            const translationKey = dossier.complete ? 'completeDossierMessage' : 'incompleteDossierMessage';
            return this.t(translationKey, {
                dossierLabel,
            });
        },
        getDossierActionLabel(dossier) {
            return dossier.complete ? this.completeDossierActionMessage : this.incompleteDossierActionMessage;
        },
        getDossierCheckBoxName(dossierId) {
            return `wantsToSubmitRentalApplication-${dossierId}`;
        },
        setWantsToSubmitRentalApplication(checked, dossierId) {
            const index = _.findIndex(this.dossierToSubmit, {dossierId});
            if (index >= 0) {
                this.dossierToSubmit[index].checked = checked;
            } else {
                this.dossierToSubmit.push({dossierId, checked});
            }
            this.$emit('input', {
                dossiers: _.map(_.filter(this.dossierToSubmit, {checked: true}), 'dossierId'),
                submitRentalApplication: _.some(this.dossierToSubmit, {'checked': true}),
            });
        },
    },
    template: template(),
};
