const $ = require('jquery');
const async = require('async');
const template = require('../templates/account/accountManagementView.jade');
const shortcutLinkTemplate = require('../templates/account/shortcutLink.jade');
const AccountForm = require('./AccountForm');
const Account = require('./Account');
const VueView = require('../vue/VueView');
const FieldCheck = require('../fields/FieldCheck');
const _ = require('lodash');
const FormLoadingState = require('../FormLoadingState');
const {i18n: {translate}, resourceUrl} = require('fack');
const HistoryHelper = require('../utils/HistoryHelper');
const Roles = require('../../common/Roles');
const DisableAccountFormView = require('../views/DisableAccountFormView');
const DisabledReasonTypes = require('../admin/account/DisabledReasonTypes');
const SpinnerHelper = require('../utils/SpinnerHelper');
const ScrollHelper = require('../utils/ScrollHelper');
const EventPack = require('../utils/EventPack');
const ContractAndExtensionAccounts = require('../data/ContractAndExtensionAccounts');
const ImageHelper = require('../ImageHelper');
const agencyLogoSize = require('../ads/agencyLogoSize');
const {BLUR_TYPES, getBlurTypeKeyFromBlurType} = require('../BlurHelper');
const accountFormTranslate = require('../utils/accountForm/accountFormTranslate');
const isValidPosition = require('../utils/isValidPosition');
const UrlHelper = require('../../common/UrlHelper');
const getNameForContactField = require('../utils/accountForm/getNameForContactField');
const {removeDataFromOwnersFromAccount} = require('./AccountForModification');
const i18nMixin = require('../vue/components/mixins/i18n');
const {CLIENT_ACCOUNT_TYPES, CONTRACT_ACCOUNT_TYPE} = require('../../common/AccountTypes');
const ServerConfig = require('../ServerConfig');
const LocalBannersManagement = require('../accountManagement/components/LocalBannersManagement/LocalBannersManagement');
const AccountLogo = require('../ad/components/AccountLogo');
const {getAccountName} = require('../../common/accountHelpers');
const CancelableCallback = require('../utils/CancelableCallback');
const {isKartoEnabled} = require('../utils/Karto');

const {isMobile} = require('browser-detect');

const POSSIBLE_CONTACT_MODES_AVAILABLE_ON_ADS = ['emailOnly', 'phoneOnly', 'emailAndPhone'];

const BACKGROUND_IMAGES_COUNT = 4;
const BACKGROUND_IMAGES_URLS_LIST_FOR_PREVIEW = _.map(_.range(1, BACKGROUND_IMAGES_COUNT + 1), (imageNumber) => {
    return resourceUrl(`images/account/backgroundForPreviewLogoOnAds/background_${imageNumber}.png`);
});
const IMAGE_UPLOADS_STATUS_CHANGED_EVENT = 'image-uploads-status-changed';

const ORDERED_DISTINCT_CONTACT_SECTIONS_ROLES = ['buy', 'rent', 'report'];

const VISYOPLUS_CONTACT_EMAIL_TYPE = 'visyo-plus-ws';

const OPTIONS_THAT_NEED_CUSTOMER_ID = ['eligibleForIkimoTech'];

module.exports = class AccountManagementView extends VueView {
    constructor(options) {
        super({
            template,
        }, {
            el: '.accountManagementContainer', // limit vue to a subSection because we're using jQuery to add shortcut block to the dom
        });
        this.options = options;
        this._eventPack = new EventPack();
        this._imageUploadsStatus = {};
    }

    loadData(options, cb) {
        if (Account.isAccountModifier()) {
            async.parallel({
                contractAccounts: (cb) => ContractAndExtensionAccounts.get('contractType', cb),
                extensionAccounts: (cb) => ContractAndExtensionAccounts.get('extensionType', cb),
            }, (err, {contractAccounts, extensionAccounts}) => {
                _.extend(options, {
                    contractAccounts,
                    extensionAccounts,
                });
                cb(err, options);
            });
        } else {
            _.defer(cb);
        }
    }

    // eslint-disable-next-line complexity
    show(options) {
        const accountWithEffectiveData = options.account;
        const editedAccountWithoutDataFromOwners = removeDataFromOwnersFromAccount(accountWithEffectiveData);
        this._editedAccountWithoutDataFromOwners = editedAccountWithoutDataFromOwners;
        const hasPasswordAuth = Account.hasPasswordAuth(editedAccountWithoutDataFromOwners);
        const hasConnectedThroughSection = Account.hasConnectedThroughSection(editedAccountWithoutDataFromOwners);
        const possibleRoles = Roles.getKeysList();
        const roleByCategory = Roles.getRolesByCategory();
        const passwordCreds = _.filter(editedAccountWithoutDataFromOwners.credentials || [], function (cred) {
            return cred.type == 'password';
        });
        const logins = _.map(passwordCreds, 'id');
        const mainContact =
            _.find(editedAccountWithoutDataFromOwners.contacts, contact => _.includes(contact.roles, 'main'));
        const isLoggedByEmail = logins.includes(editedAccountWithoutDataFromOwners.email) ||
            logins.includes(_.get(mainContact, 'email'));
        ensureFlags(editedAccountWithoutDataFromOwners);

        const isAccountModifier = Account.isAccountModifier();
        const enableAllSections = isAccountModifier || options.myAccount;

        const {isPro} = options;
        const displayForPro = isAccountModifier || isPro;
        const approvalQuestions = {
            wantsToReceiveDailyReportEmails: {
                display: displayForPro,
            },
            wantsToReceiveWeeklyReportEmails: {
                display: displayForPro,
            },
            wantsToReceiveLeadingAdsSuggestions: {
                display: displayForPro && accountWithEffectiveData.canBuyTemporaryLeadingAds,
                default: true,
            },
            wantsToReceiveSimilarSuggestions: {
                display: enableAllSections && !isPro,
            },
            wantsToReceiveDirectOffersAndNewsLetter: {
                display: enableAllSections,
            },
            wantsToReceivePartnerOffers: {
                display: enableAllSections,
            },
        };
        const disallowedDisabledReasonType = 'duplicate'; // the UI cannot handle duplicate accounts
        const showDisabledAccountSection = isAccountModifier
            && editedAccountWithoutDataFromOwners.disabled
            && editedAccountWithoutDataFromOwners.disabledReasonType != disallowedDisabledReasonType;
        const disabledReasonTypes = _.without(DisabledReasonTypes, disallowedDisabledReasonType);

        const allContractAccountsIds = _.map(options.contractAccounts, 'id');
        this._allContractAndExtensionAccountsIds = _.clone(allContractAccountsIds);
        const allExtensionAccountsIds = _.map(options.extensionAccounts, 'id');
        this._allContractAndExtensionAccountsIds.push(...allExtensionAccountsIds);
        const userOwnerIds = _.get(editedAccountWithoutDataFromOwners, 'dataFromUser.ownerIds', []);
        const contractAccountIds = _.intersection(userOwnerIds, allContractAccountsIds);
        const extensionAccountIds = _.intersection(userOwnerIds, allExtensionAccountsIds);
        const userOwnerIdsExcludingContractsAndExtensions = _.difference(
            userOwnerIds,
            [...contractAccountIds, ...extensionAccountIds]
        );
        const importOwnerIds = _.get(editedAccountWithoutDataFromOwners, 'dataFromImport.ownerIds', []);

        const view = this;
        const editedAccountCanEnableItsOwnPublicPage = accountWithEffectiveData.canEnablePublicPage;
        const isSelfEditing = editedAccountWithoutDataFromOwners.id === Account.getAuthenticatedAccountId();
        const currentUserCanEnablePublicPage =
            (isSelfEditing && editedAccountCanEnableItsOwnPublicPage) || isAccountModifier;
        const {company, contactEmailType} = editedAccountWithoutDataFromOwners;
        const contact = editedAccountWithoutDataFromOwners.contact || mainContact;
        const isAdmin = Account.isAdmin();
        const logoAndNameOnAdsPhotosEnabledDefaultValue =
            _.get(accountWithEffectiveData, 'proOptions.logoAndNameOnAdsPhotosEnabled');
        // @vue/component
        const vueOptions = {
            components: {
                LocalBannersManagement,
                AccountLogo,
            },
            constants: {
                BACKGROUND_IMAGES_URLS_LIST_FOR_PREVIEW,
            },
            mixins: [
                require('../fields/vue/validationMixin')({
                    initBootstrapValidator: false, // already initialised later in AccountForm. TODO: remove from AccountForm
                }),
                i18nMixin(),
            ],
            provide: {
                account: editedAccountWithoutDataFromOwners,
            },
            data() {
                const roles = _(possibleRoles)
                    .keyBy() // add role as key
                    .mapValues(role => _.includes(editedAccountWithoutDataFromOwners.roles, role))
                    .value();
                return _.extend({
                    login: _.first(logins),
                    roles,
                    contractAccountId: _.first(contractAccountIds),
                    extensionAccountIds,
                    userOwnerIdsExcludingContractsAndExtensions,
                    importOwnerIds,
                    canSeeRoleFields: isAdmin,
                    buyRentReportContacts: _.map(
                        ORDERED_DISTINCT_CONTACT_SECTIONS_ROLES,
                        role => this.getContactForRole(role)
                    ),
                    otherContacts:
                        _(editedAccountWithoutDataFromOwners.contacts || [])
                            .filter(contact => {
                                const {roles: rolesInNewContactFormat, role} = contact;
                                const roles = rolesInNewContactFormat || [role];
                                return _.every(roles, role => !_.includes(ORDERED_DISTINCT_CONTACT_SECTIONS_ROLES, role));
                            })
                            .map(contact => _.extend(getDefaultContact(), contact))
                            .value(),
                    agencyPositionInfo: getAgencyPositionInfo(editedAccountWithoutDataFromOwners),
                    useKarto: isKartoEnabled('accountModificationForm'),
                    showAgencyPositionWarning: false,
                    currentUserCanEnablePublicPage,
                    selectedContactType: contactEmailType,
                    selectedContract: null,
                    accountDisplayName: getAccountName(editedAccountWithoutDataFromOwners),
                    pendingGetContractAccountCallback: null,
                }, pickWithUndefinedByDefault(editedAccountWithoutDataFromOwners, [
                    'audienceTargetingProducts',
                    'customerId',
                    'agencyEmailCampaign',
                ]), _.mapValues({
                    accountId: 'id',
                    agencyMainLogo: 'company.logoUrl',
                    photoWatermark: 'company.photoWatermarkAlias',
                    agencyBackgroundImage: 'agencyBackgroundImage',
                    showRoomLogo: 'showRoom.logoUrl',
                    address: 'company.address',
                    canSeeProspectingMap: 'canSeeProspectingMap',
                    isExternalAdvertisementEnabled: 'isExternalAdvertisementEnabled',
                    contactEmailTypeAdditionalInfo: null,
                    jobAdsEnabled: 'jobAdsEnabled',
                    allowNewPropertyImport: 'allowNewPropertyImport',
                    canReceiveHasPropertyToSellContacts: 'proOptions.canReceiveHasPropertyToSellContacts',
                    eligibleForIkimoTech: 'proOptions.eligibleForIkimoTech',
                    localBannersConfiguration: 'proOptions.localBannersConfiguration',
                    agencyFeeFilename: 'agencyFees.filename',
                    visibleOnPlaceDiscoveryPage: 'proOptions.visibleOnPlaceDiscoveryPage',
                    logoAndNameOnAdsPhotosEnabled: 'proOptions.logoAndNameOnAdsPhotosEnabled',
                    preferAccountWebsiteForLogoAndNameOnAdsPhotos: 'proOptions.preferAccountWebsiteForLogoAndNameOnAdsPhotos',
                    photoWatermarkEnabled: 'photoWatermarkEnabled',
                }, path => {
                    const value = _.get(editedAccountWithoutDataFromOwners, path);
                    // clone objects to prevent "removeUnchangedValues" from removing them
                    return _.isObject(value) ? _.cloneDeep(value) : value;
                }));
            },
            computed: {
                approvalQuestions() {
                    return _.pickBy(approvalQuestions, 'display');
                },
                contactModeAvailableOnAdsOptions() {
                    return getContactModeAvailableOnAdsOptions(editedAccountWithoutDataFromOwners);
                },
                customerIdRequired() {
                    const selectedContractHasAgencyEmailCampaignEnabled = _.some(options.contractAccounts, {
                        id: this.contractAccountId,
                        agencyEmailCampaign: true,
                    });
                    const selectedExtensionHasExternalAdvertisementEnabled = _.some(this.extensionAccountIds, id =>
                        _.some(options.extensionAccounts, {
                            id,
                            isExternalAdvertisementEnabled: true,
                        }));
                    if (editedAccountWithoutDataFromOwners.accountType === CONTRACT_ACCOUNT_TYPE) {
                        return false;
                    }
                    const hasProOptionThatNeedCustomerId = _.some(OPTIONS_THAT_NEED_CUSTOMER_ID, option => {
                        return this[option] === true;
                    });
                    return !_.isEmpty(this.audienceTargetingProducts) || this.agencyEmailCampaign
                        || selectedContractHasAgencyEmailCampaignEnabled || this.isExternalAdvertisementEnabled
                        || selectedExtensionHasExternalAdvertisementEnabled || this.requiredCustomerIdForSelectedContract
                        || hasProOptionThatNeedCustomerId;
                },
                requiredCustomerIdForSelectedContract() {
                    const {selectedContract} = this;
                    let requiredCustomerId;
                    if (!selectedContract) {
                        requiredCustomerId = false;
                    } else {
                        const defaultProOptions = AccountForm.getProOptionsDefaultValues(selectedContract);
                        requiredCustomerId = _.some(OPTIONS_THAT_NEED_CUSTOMER_ID, optionThatNeedCustomerId => {
                            return defaultProOptions[optionThatNeedCustomerId] === true;
                        });
                    }
                    return requiredCustomerId;
                },
                isContactEmailTypeAdditionalInfoRequired() {
                    return this.selectedContactType === VISYOPLUS_CONTACT_EMAIL_TYPE;
                },
                redirectAllMailsTo() {
                    return ServerConfig.config.redirectAllMailsTo;
                },
                isRequiredForLocalBanners() {
                    return this.localBannersConfiguration && this.localBannersConfiguration.enabled;
                },
                account() {
                    return editedAccountWithoutDataFromOwners;
                },
                saveAndUpdateAccountButtonDesktop() {
                    return view.$element.find('.saveAndUpdateAccount');
                },
                saveAndUpdateAccountButtonMobile() {
                    return view.$element.find('.tryEditAccount');
                },
                affiliationsTitle() {
                    return translate('affiliation.title');
                },
                isWebsiteRequired() {
                    return Boolean(this.preferAccountWebsiteForLogoAndNameOnAdsPhotos || this.isRequiredForLocalBanners);
                },
                emptyWebsiteWarning() {
                    if (this.isRequiredForLocalBanners) {
                        return this.getAccountFormLabel('IsRequiredForLocalBannersText');
                    } else if (this.preferAccountWebsiteForLogoAndNameOnAdsPhotos) {
                        return this.getAccountFormLabel('isRequiredForPreferAccountWebsiteForLogoAndNameOnAdsPhotosText');
                    } else {
                        return undefined;
                    }
                },
                isLogoAndNameOnAdsPhotosEnabledWithDefaultValue() {
                    return this.logoAndNameOnAdsPhotosEnabled ||
                        (this.logoAndNameOnAdsPhotosEnabled == null && logoAndNameOnAdsPhotosEnabledDefaultValue);
                },
                photoWatermarkInImagePreviewSrc() {
                    const logo = this.photoWatermark || this.agencyMainLogo;
                    return ImageHelper.getImageUrlFromAlias(logo, agencyLogoSize);
                },
                showPhotoWatermarkImageSelector() {
                    const {photoWatermarkEnabled, isLogoAndNameOnAdsPhotosEnabledWithDefaultValue} = this;
                    const isPhotoWatermarkEnabledWithDefaultValue = photoWatermarkEnabled
                        || (photoWatermarkEnabled == null && accountWithEffectiveData.photoWatermarkEnabled);
                    return isPhotoWatermarkEnabledWithDefaultValue || isLogoAndNameOnAdsPhotosEnabledWithDefaultValue;
                },
            },
            watch: {
                customerIdRequired() {
                    this.generateCustomerIdIfNeeded();
                },
                contractAccountId() {
                    this.updateSelectedContract();
                },
                logoAndNameOnAdsPhotosEnabled(logoAndNameOnAdsPhotosEnabled) {
                    if ((logoAndNameOnAdsPhotosEnabled == null && logoAndNameOnAdsPhotosEnabledDefaultValue)
                            || logoAndNameOnAdsPhotosEnabled) {
                        this.photoWatermarkEnabled = false;
                    } else if (this.preferAccountWebsiteForLogoAndNameOnAdsPhotos) {
                        this.preferAccountWebsiteForLogoAndNameOnAdsPhotos = false;
                    }
                },
            },
            mounted() {
                this.updateSelectedContract();
            },
            methods: {
                _clearGetContractAccountRequest() {
                    const contractAccountIdCancelableCallback = this.pendingGetContractAccountCallback;
                    if (contractAccountIdCancelableCallback) {
                        contractAccountIdCancelableCallback.cancel();
                        this.pendingGetContractAccountCallback = null;
                    }
                },
                updateSelectedContract() {
                    this.selectedContract = null;
                    const {contractAccountId} = this;
                    if (contractAccountId) {
                        this._clearGetContractAccountRequest();
                        this.pendingGetContractAccountCallback = CancelableCallback((err, account) => {
                            if (err) {
                                console.error('Could not get selected contract from contract id ', contractAccountId);
                            } else if (account) {
                                this.selectedContract = account;
                            }
                        });
                        Account.get(contractAccountId, this.pendingGetContractAccountCallback);
                    }
                },
                generateCustomerIdIfNeeded() {
                    if (this.customerIdRequired && !this.customerId) {
                        this.customerId = this.getCredentialNameForCustomerIdPrefill();
                    }
                },
                getAccountTypes() {
                    const accountTypeInitialValue = {
                        value: '',
                        label: accountFormTranslate('accountType.initial'),
                    };
                    const accountTypeValues = _.map(CLIENT_ACCOUNT_TYPES, value => {
                        return {value, label: accountFormTranslate(`accountType.${value}`)};
                    });
                    return [accountTypeInitialValue, ...accountTypeValues];
                },
                getContractNames() {
                    return [
                        {
                            value: null,
                            label: translate('contractNames.noContract'),
                        },
                        ..._.map(options.contractAccounts, contractAccount => {
                            return {
                                value: contractAccount.id,
                                label: contractAccount.label,
                            };
                        }),
                    ];
                },
                getExtensionTypes() {
                    return _.map(options.extensionAccounts, extensionAccount => {
                        const {id: value, label} = extensionAccount;
                        return {value, label};
                    });
                },
                nullLabelFor(name) {
                    return AccountForm.getDefaultLabelForBoolean(editedAccountWithoutDataFromOwners, name);
                },
                getImagePreviewStyle(backgroundUrl) {
                    return {backgroundImage: 'url("' + backgroundUrl + '")'};
                },
                setUploadingFile(fileName, isUploading) {
                    this.toggleSaveButtons(isUploading);
                    view._setUploadingImage(fileName, isUploading);
                },
                getFormattedOptions(translationKey, translator = accountFormTranslate) {
                    const translationValues = translator(translationKey, {returnObjectTrees: true});
                    return _.map(translationValues, (label, value) => {
                        return {
                            value,
                            label,
                        };
                    });
                },
                getFormattedAndSortedOptions(translationKey, translator) {
                    return _.sortBy(this.getFormattedOptions(translationKey, translator), ({label}) => label.toLowerCase());
                },
                getDisabledReasonTypes() {
                    return _.map(disabledReasonTypes, value => {
                        return {
                            value,
                            label: accountFormTranslate(`disableAccount.reasons.${value}`),
                        };
                    });
                },
                getBlurTypeSelectOptions(nameSuffix) {
                    return _.map(BLUR_TYPES, blurType => {
                        const translationKey = `blurTypeOptions${nameSuffix}.${blurType.key}`;
                        const blurTypeLabel = translate(translationKey, {
                            defaultValue: translate('blurTypeOptions.' + blurType.key),
                        });
                        return {
                            value: blurType.key,
                            label: blurTypeLabel,
                        };
                    });
                },
                getBlurTypeSelectValue(pathInAccount) {
                    return getBlurTypeKeyFromBlurType(_.get(editedAccountWithoutDataFromOwners, pathInAccount));
                },
                getAffiliationsPlaceholder() {
                    const possibleAffiliations = _.map(this.getAffiliations(), 'label').join(', ');
                    return translate('affiliation.placeholder', {possibleAffiliations});
                },
                getAffiliations() {
                    return this.getFormattedOptions('affiliation.values', translate);
                },
                accountFormTranslate,
                getNameForContactField,
                getContactForRole(role) {
                    const contact = _.find(editedAccountWithoutDataFromOwners.contacts, contact => {
                        return contact.role === role || _.includes(contact.roles, role);
                    });
                    return _.extend(getDefaultContact(), {
                        role,
                        roles: [role],
                    }, contact);
                },
                getTitleForRole({role}) {
                    return accountFormTranslate(`${role}ContactSectionTitle`);
                },
                getRoleText({role}) {
                    return accountFormTranslate('contactRole.role', {role});
                },
                getAnchorId({role}) {
                    return 'section_' + UrlHelper.slugify(this.getTitleForRole({role}));
                },
                addNewContact() {
                    this.otherContacts.push(getDefaultContact());
                },
                removeOtherContactAt(index) {
                    this.$delete(this.otherContacts, index);
                },
                getCredentialNameForCustomerIdPrefill() {
                    const credentials = editedAccountWithoutDataFromOwners.credentials || [];
                    return _.get(_.first(credentials), 'id');
                },
                splitCommaSeparatedStringIntoArray(str) {
                    return str ? str.split(',') : [];
                },
                getAccountFormLabel(translationKey, name, context) {
                    return accountFormTranslate(name ? `${translationKey}.${name}` : translationKey, context);
                },
                toggleSaveButtons(isDisabled) {
                    const action = isDisabled ? 'start' : 'stop';
                    if (isMobile()) {
                        SpinnerHelper[`${action}ButtonSpinner`](this.saveAndUpdateAccountButtonMobile);
                    } else {
                        SpinnerHelper[`${action}ButtonSpinner`](this.saveAndUpdateAccountButtonDesktop);
                    }
                },
                proOptionsTranslate(name) {
                    return accountFormTranslate('proOptions.' + name);
                },
            },
        };
        super.show({
            showPasswordSection: enableAllSections && hasPasswordAuth,
            showEmailAsLoginSection: enableAllSections && isLoggedByEmail,
            showLoginSectionNotLoggedByEmail: (enableAllSections || Account.isAccountDisabler()) && !isLoggedByEmail,
            showBlurTypeButton: enableAllSections && isAccountModifier,
            showCompanySection: enableAllSections && (company != null),
            showProContactSection: enableAllSections && (contact != null && company != null),
            showOtherContactsSection: enableAllSections && (contact != null && company != null), //no other contacts if no primary contact
            showApprovalQuestionsSection: enableAllSections,
            showConnectedThroughSection: enableAllSections && hasConnectedThroughSection,
            showAdminSection: isAdmin,
            showRolesSection: Account.getPropertyValue('roleDispenser'),
            Account,
            showDisabledAccountSection,
            showServicesSection: displayForPro,
            showAutomaticLeadingAds: Account.canMarkAdsAsLeading(accountWithEffectiveData),
            showAutomaticHighlightedAds: Account.canHighlightAds(accountWithEffectiveData),
            showAgencyPageSection: enableAllSections && (company != null),
            showDisableAccountField: Account.isAccountDisabler(),
            showPublicContactSection: company == null && enableAllSections,
            showShowRoomSection: company == null && isAdmin,
            showFootNote: enableAllSections,
            canModifyAccount: !Account.isShowRoom() || isAccountModifier,
            accountId: editedAccountWithoutDataFromOwners.id,
            accountType: editedAccountWithoutDataFromOwners.accountType,
            isSelfEditing,
            accessToken: Account.getAccessToken(),
            title: this.options.title,
            possibleRoles,
            roleByCategory,
            isRegistered: Account.isRegistered(editedAccountWithoutDataFromOwners),
            disableRequiredFields: isAccountModifier,
            askForContactEmailType: isAdmin,
            BLUR_TYPES,
            editedAccountCanEnableItsOwnPublicPage,
        }, vueOptions);
        this.fillShortcutsBlock();
        const $element = this.$element;

        this.accountForm = AccountForm({
            contractAccounts: options.contractAccounts,
            $form: $element,
            submit: _.bind(this.submitForm, this),
            account: editedAccountWithoutDataFromOwners,
        });
        this._eventPack.on($element, 'click', '.cancelUpdateAccount', exitUpdateAccount);
        if (hasConnectedThroughSection) {
            const credType = Account.getCredType(editedAccountWithoutDataFromOwners);
            const connectType = translate('accountManagementView.' + credType) || credType;
            $element.find('#connectedThrough').text(translate('accountManagementView.connectedThrough', {val: connectType}));
        }
        $element.find('.warningBlockForImport').toggle(Boolean(editedAccountWithoutDataFromOwners.importAccountId));
        $element.find('[name="blurTypes.global"]').on('change', _.bind(this.spreadBlurType, this));

        $element.find('.linksBlockLink').on('click', _.bind(this.anchorShift, this));
    }

    fillShortcutsBlock() {
        const sectionTitles = _.map(
            this.$element.find('.formBlockContainer .formMenuEntry'),
            titleSection => $(titleSection).text()
        );
        const $linksBlock = this.$element.find('.accountManagementPageHeaderMenu .linksBlock');
        $linksBlock.empty();
        _.each(sectionTitles, sectionTitle => {
            $linksBlock.append(this.renderTemplate(shortcutLinkTemplate, {sectionTitle}));
        });
    }

    anchorShift(e) {
        e.preventDefault();
        e.stopPropagation();
        const nameAnchor = e.currentTarget.getAttribute('href');
        const accountTitleHeight = this.$element.find('.accountManagementPageHeaderTitle').height();
        const anchor = this.$element.find(nameAnchor);
        ScrollHelper.scrollToElement(anchor[0], {animated: true, offset: -accountTitleHeight});
    }

    spreadBlurType() {
        this.accountForm.spreadBlurType();
    }

    hide(options, cb) {
        if (this.accountForm) {
            this.accountForm.clear();
            this.accountForm = null;
        }
        this._eventPack.removeAllListeners();
        super.hide(options, cb);
    }

    _openDisableAccountForm(callback) {
        this.disableAccountFormView = new DisableAccountFormView();
        this.disableAccountFormView.show({
            endCallback: callback,
        });
    }

    _showUpdateAccount(additionalData) {
        const $element = this.$element;
        const $validateButton = $element.find('.tryCreateAccount');
        $('input').blur();
        const loadingState = new FormLoadingState({
            $form: $element,
            $button: $validateButton,
        });
        loadingState.show();
        this.updateAccount(done, additionalData);

        function done() {
            loadingState.hide();
            const $saveAndUpdateAccountButton = $element.find('.saveAndUpdateAccount');
            if ($saveAndUpdateAccountButton.length) {
                SpinnerHelper.stopButtonSpinner($saveAndUpdateAccountButton);
            }
            const $tryEditAccountButton = $element.find('.tryEditAccount');
            if ($tryEditAccountButton.length) {
                SpinnerHelper.stopButtonSpinner($tryEditAccountButton);
            }
        }
    }

    _getContacts() {
        let contacts = this.vm.buyRentReportContacts.concat(this.vm.otherContacts);
        contacts = _(contacts)
            .map((contact, id) => ({id, contact: _.omit(contact, 'roles')}))
            .sortBy('id')
            .map('contact')
            .filter(isValidContact)
            .map(contact => _.pickBy(contact, Boolean))
            .value();
        const contactsFromImport = _.get(this._editedAccountWithoutDataFromOwners, 'dataFromImport.contacts');
        if (_.isEmpty(contactsFromImport) && _.isEmpty(contacts)) {
            contacts = null; // back to data from import
        }
        return contacts;
    }

    submitForm() {
        const {$element} = this;
        const $saveAndUpdateAccountButton = $element.find('.saveAndUpdateAccount');
        if ($saveAndUpdateAccountButton.length) {
            SpinnerHelper.startButtonSpinner($saveAndUpdateAccountButton);
        }
        const $tryEditAccountButton = $element.find('.tryEditAccount');
        if ($tryEditAccountButton.length) {
            SpinnerHelper.startButtonSpinner($tryEditAccountButton);
        }
        const accountData = this.accountForm.getAccountData(this._getAdditionalDataFromVm());
        this._addOwnerIdsIfChanged(accountData);
        accountData.contacts = this._getContacts();
        const editedAccountWithoutDataFromOwners = this._editedAccountWithoutDataFromOwners;
        const newDisabledState = accountData.disabled;
        if (((newDisabledState === null && editedAccountWithoutDataFromOwners.dataFromImport.disabled)
            || (newDisabledState))
            && editedAccountWithoutDataFromOwners
            && (editedAccountWithoutDataFromOwners.disabled != newDisabledState)) {
            this._openDisableAccountForm(_.bind(this._showUpdateAccount, this, accountData));
        } else {
            this._showUpdateAccount(accountData);
        }
    }

    _addOwnerIdsIfChanged(accountData) {
        if (Account.isAccountModifier()) {
            const {contractAccountId, extensionAccountIds, userOwnerIdsExcludingContractsAndExtensions} = this.vm;
            let ownerIds = [];
            if (userOwnerIdsExcludingContractsAndExtensions) {
                ownerIds = _.difference(userOwnerIdsExcludingContractsAndExtensions, this._allContractAndExtensionAccountsIds);
            }
            if (contractAccountId) {
                ownerIds.push(contractAccountId);
            }
            if (extensionAccountIds) {
                ownerIds.push(...extensionAccountIds);
            }
            const previousUserOwnerIds = _.get(this._editedAccountWithoutDataFromOwners, 'dataFromUser.ownerIds', []);
            if (!_.isEqual(ownerIds, previousUserOwnerIds)) {
                accountData.ownerIds = ownerIds;
            }
        }
    }

    //todo: handle all vm fields(ownerIds, contacts, etc.) in here, to be processed in AccountForm
    _getAdditionalDataFromVm() {
        const additionalDataFromVm = {};
        if (Account.isAccountModifier()) {
            const {vm} = this;
            _.extend(additionalDataFromVm, _.pick(vm, [
                'customerId',
                'audienceTargetingProducts',
                'agencyEmailCampaign',
            ]), {
                company: {
                    manualPosition: _.get(vm, 'agencyPositionInfo.manual'),
                },
                proOptions: {
                    localBannersConfiguration: vm.localBannersConfiguration,
                },
            });
        }
        return additionalDataFromVm;
    }

    updateAccount(cb, additionalData) {
        this._onUploadsReady(err => {
            if (err) {
                if (err != 'Upload canceled.') {
                    const $elem = $('form #logoUpload');
                    FieldCheck.toggleError($elem, false, 'photoUploadError');
                    FieldCheck.scrollToElement($elem);
                }
                cb(err);
            } else {
                const accountData = _.extend(this.accountForm.getAccountData(), additionalData);
                if (accountData.accountType == '') {
                    accountData.accountType = null;
                }
                if (!accountData.password) {
                    delete accountData.password;
                }
                if (accountData.agencyFees && accountData.agencyFees.filename) {
                    accountData.agencyFeeUrl = null;
                }

                accountData.id = this._editedAccountWithoutDataFromOwners.id;
                Account.update(accountData, err => {
                    if (err) {
                        alert('Erreur: ' + err);
                    } else {
                        this.$element.find('.successModal')
                            .modal({
                                backdrop: 'static',
                                keyboard: false,
                                show: true,
                            })
                            .one('hidden.bs.modal', function () {
                                _.defer(exitUpdateAccount);
                            });
                    }
                    cb(err);
                });
            }
        });
    }

    _setUploadingImage(imageName, isUploading) {
        this._imageUploadsStatus[imageName] = isUploading;
        this.emit(IMAGE_UPLOADS_STATUS_CHANGED_EVENT);
    }

    _onUploadsReady(cb) {
        if (_.some(this._imageUploadsStatus)) {
            const onStatusChanged = () => {
                if (!_.some(this._imageUploadsStatus)) {
                    this.removeListener(IMAGE_UPLOADS_STATUS_CHANGED_EVENT, onStatusChanged);
                    cb();
                }
            };
            this.on(IMAGE_UPLOADS_STATUS_CHANGED_EVENT, onStatusChanged);
        } else {
            cb();
        }
    }
};

function ensureFlags(editedAccount) {
    const defaultValues = {
        visibleOnMap: false,
        visibleInUserDirectory: true,
        transmitsInheritableAttributes: true,
        allowAdManualPlacement: false,
    };
    _.defaults(editedAccount, defaultValues);
}

function exitUpdateAccount() {
    HistoryHelper.back();
}

function getContactModeAvailableOnAdsOptions(account) {
    const defaultValue = AccountForm.getDefaultValueForContactModeAvailableOnAds(account);
    return _.map(POSSIBLE_CONTACT_MODES_AVAILABLE_ON_ADS, value => ({
        label: _t(value),
        value,
    })).concat({
        label: translate('byDefault_withValue', {value: _t(defaultValue)}),
        value: 'default',
    });

    function _t(key) {
        return translate('accountForm.contactModeAvailableOnAds.' + key);
    }
}

function isValidContact(contact) {
    return Boolean(contact && (contact.lastName || contact.firstName || contact.email || contact.phone));
}

function getDefaultContact() {
    return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        alwaysReceiveCarbonCopy: false,
        overridePhoneInAds: false,
        role: '',
        roles: [],
        id: _.uniqueId('new-contact-'),
    };
}

function getAgencyPositionInfo({position}) {
    const {
        lat,
        lon: lng,
        origin,
        automatic,
    } = position || {};
    const agencyPosition = isValidPosition({lat, lng}) ? {lat, lng} : undefined;
    const isManualPosition = origin === 'manual';
    if (isManualPosition) {
        return {
            automatic,
            manual: agencyPosition,
        };
    } else {
        return {
            automatic: agencyPosition,
        };
    }
}

function pickWithUndefinedByDefault(object, paths) {
    return _.zipObject(paths, _.at(object, paths));
}
